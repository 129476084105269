<template>
    <b-overlay :show="showLoader">

        <!-- form demand -->
        <validation-observer ref="demandValidation">
            <b-form class="auth-login-form mt-3">
                <b-form-group>
                    <validation-provider #default="{ errors }" name="ime" rules="required|min:3|max:30">
                        <b-form-input class="demand" v-model="demand.name" placeholder="Ime" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group>
                    <validation-provider #default="{ errors }" name="priimek" rules="required|min:3|max:30">
                        <b-form-input class="demand" v-model="demand.surname" placeholder="Priimek" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group>
                    <validation-provider #default="{ errors }" name="e-naslov" rules="required|email">
                        <b-form-input class="demand" v-model="demand.email" placeholder="E-naslov" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group>
                    <span style="position: absolute; right: 10px; margin-top: 12px;">
                        <fa class="ml-1" id="supported-phone-formats-tooltip" icon="question-circle" color="#72a5d8" />
                        <b-tooltip target="supported-phone-formats-tooltip" placement="topright" triggers="hover focus" custom-class="expanded-tooltip">
                            Podprti formati:<br>
                            +386 51 123 456<br>
                            00386 51 123 456<br>
                            051 123 456
                        </b-tooltip>
                    </span>
                    <validation-provider #default="{ errors }" name="telefonska številka" rules="required|phone_number">
                        <b-form-input class="demand" v-model="demand.telephone_number" placeholder="Telefonska številka" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group>
                    <validation-provider #default="{ errors }" name="naslov" rules="required">
                        <b-form-input class="demand" v-model="demand.address" placeholder="Naslov (ulica, kraj, poštna št.)" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group>
                    <validation-provider #default="{ errors }" name="regija" rules="required">
                        <b-form-input class="demand" v-model="demand.region" placeholder="Regija" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group>
                    <validation-provider #default="{ errors }" name="sporočilo" rules="required">
                        <b-form-textarea class="demand" v-model="demand.content" rows="8" placeholder="Sporočilo" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group class="text-right mt-2">
                    <b-button pill class="login-button" @click="validationFormDemand">POŠLJI POPRAVŠEVANJE</b-button>
                </b-form-group>
            </b-form>
        </validation-observer>
    </b-overlay>

</template>
<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, min, atLeastOneDigit, atLeastOneUppercase, PhoneNumber, confirmed } from '@validations'
    import {BFormGroup,  BForm, BButton, BFormInput, BTooltip, BOverlay, BFormTextarea} from 'bootstrap-vue'
    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            BFormGroup,
            BForm,
            BButton,
            BFormInput,
            BTooltip,
            BOverlay,
            BFormTextarea
        },
        data() {
            return {
                demand: {name: '', surname: '',  email: '', telephone_number: '', address: '', region: '', content: ''},
                required,
                email,
                min,
                atLeastOneDigit,
                atLeastOneUppercase,
                PhoneNumber,
                confirmed,

                showLoader: false
            }
        },
        methods: {
            validationFormDemand() {
                this.$refs.demandValidation.validate().then(success => {
                    if (success) {
                        this.send()
                    }
                })
            },
            send() {
                const thisIns = this
                thisIns.showLoader = true

                const loadPromise = this.$http.post('/api/user/v1/send_mail/send_demand', this.demand)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Povprašeavnje je bilo uspešno poslano')
                    thisIns.demand = {name: '', surname: '',  email: '', telephone_number: '', address: '', region: '', content: ''}
                    thisIns.showLoader = false
                    thisIns.$emit('on-demand-successful')
                }).catch(function() {
                    thisIns.$printError('Prišlo je do napake pri pošiljanju podatkov, prosimo poskusite ponovno!')
                    thisIns.showLoader = false
                })
            }
        }

    }
</script>
<style scoped>
.login-button {
  background: #72A5D8 !important;
  font-weight: bold;
  border: none;
  color: white !important;
}
.login-button:hover {
  box-shadow: none !important;
}
.form input {
  background-color: transparent !important;
}
.text, .login-select input::placeholder {
  color: #626363;
}
.login::placeholder, .demand::placeholder, .demand-phone input::placeholder {
  color: #626363 !important;
}
.input-group-text {
  background-color: #009FD4;
  border: 1px solid #009FD4;
  color: white;
}
input:focus, .form-control:focus + .input-group-append .input-group-text {
  background-color: white !important;
  border-color: white !important;
  box-shadow: 0 3px 10px 0 rgba(0,0,0, 0.1) !important;
}
</style>
<style>
.tooltip .arrow{
  display: none !important;
}
.demand-form .tooltip {
  position: absolute !important;
  right: 10px !important;
  margin-top: 12px !important;
}
.select-manager .v-select .vs__dropdown-toggle {
  background-color: #F4F4F4 !important;
}
.select-manager .v-select .vs__dropdown-toggle {
  height: 49px;
}
.select-manager .v-select .vs__selected, .select-manager .v-select input {
  padding-left:10px !important;
}
.select-manager .v-select .vs__dropdown-toggle .vs__actions > span {
  width: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>