<template>
    <!-- form login -->
    <b-overlay :show="showLoader">
        <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-1">
                <!-- email -->
                <b-form-group>
                    <validation-provider #default="{ errors }" name="e-naslov" rules="required|email">
                        <b-input-group>
                            <b-input-group-prepend is-text>
                                <fa class="icon" icon="user"/>
                            </b-input-group-prepend>
                            <b-form-input class="login" id="login-email" v-model="userEmail" name="login-email" placeholder="e-naslov" />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <!-- forgot password -->
                <b-form-group>
                    <validation-provider #default="{ errors }" name="geslo" rules="required">
                        <b-input-group>
                            <b-input-group-prepend is-text>
                                <fa class="icon" icon="lock"/>
                            </b-input-group-prepend>
                            <b-form-input class="login" id="login-password" v-model="password" :type="showPassword ? 'text' : 'password'" name="login-password" placeholder="geslo"/>
                            <b-input-group-append style="background-color: #f4f4f4;">
                                <b-img class="px-2" fluid :src=" showPassword ? eyeOpen : eyeClosed" @click="showPassword = !showPassword" style="cursor: pointer;" />
                            </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <div class="font-small-3" style="color: black; margin-top: 5px;">Se ne morete prijaviti? Pred prijavo, se morate najprej registrirati! Ali ste <b-link href="/pozabljeno-geslo" class="text"><span style="font-weight: bold; cursor: pointer;" >pozabili geslo</span></b-link>?</div>
                </b-form-group>
                <!-- checkbox -->
                <b-row class="mt-4" align-v="center">
                    <b-col cols="12" sm="6" align="left">
                        <label class="check m-0">
                            <input type="checkbox" :value="false" v-model="rememberMe" name="remember-me"/>
                            <span class="remember mark"></span>
                            <span class="text text-center remember-me">
                                Ostanite prijavljeni
                            </span>
                        </label>
                    </b-col>
                    <b-col cols="12" sm="6" class="d-flex justify-content-end">
                        <b-button class="login-button px-3 py-1 m-0" pill @click="validationFormLogin">PRIJAVA</b-button>
                    </b-col>
                </b-row>
                <!-- submit buttons -->
            </b-form>
        </validation-observer>
    </b-overlay>

</template>
<script>
    import {BRow, BCol, BFormGroup, BForm, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BLink, BButton, BFormInput, BOverlay, BImg} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            BRow,
            BCol,
            BFormGroup,
            BForm,
            BInputGroup,
            BInputGroupPrepend,
            BLink,
            BOverlay,
            BButton,
            BFormInput,
            BImg,
            BInputGroupAppend
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                password: '',
                showPassword: false,
                userEmail: '',
                rememberMe: false,
                required,
                email,
                showLoader: false,
                eyeOpen: require('@/assets/images/login/icon_eye_open_.svg'),
                eyeClosed: require('@/assets/images/login/icon_eye_close_.svg')
            }
        },
        methods: {
            async validationFormLogin() {
                const res = await this.$refs.loginValidation.validate()
                if (res) {
                    await this.loginJWT()
                }
            },
            async loginJWT() {
                this.showLoader = true

                const payload = {
                    email: this.userEmail,
                    password: this.password,
                    remember_me: this.rememberMe
                }

                let progress = 'login'
                try {
                    // it's important that calls are made one after another, so Promise.all(), which makes them parallel will not work here
                    const loginResponse = await this.$http.post('/api/user/v1/login', payload)
                    await this.$store.dispatch('user/login', loginResponse.data.token)
                    await this.$store.dispatch('user/setUserData', loginResponse.data)

                    progress = 'sync'
                    await this.$store.dispatch('unreadNotification/updateUnreadNotification')
                    await this.$store.dispatch('shoppingCart/syncOnLogin')
                    this.$emit('on-login-successful')
                } catch (error) {
                    if (progress === 'login') this.$printWarning(this.$t('login.wrong_password'))
                    else if (progress === 'sync') this.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                } finally {
                    this.showLoader = false
                }

            },
            async onEnterPressed(event) {
                const pressedKey = event.key || event.keyCode
                if (pressedKey === 'Enter' || pressedKey === 13) { 
                    await this.validationFormLogin()
                }
            }
        },
        mounted() {
            window.addEventListener('keyup', this.onEnterPressed, true)
        },
        beforeDestroy() {
            window.removeEventListener('keyup', this.onEnterPressed, true)
        }
    }
</script>
<style scoped>
    .input-group > .input-group-prepend > .input-group-text{
        background: #72a5D8 !important;
        border: none;
    }

    .login-button {
        background: #72a5D8 !important;
        color: white!important;
        font-weight: bold;
        border: none;
        
    }
     .login-button:hover {
        box-shadow: none !important;
    }
    .text, .login-select input::placeholder {
        color: #626363;
    }
    .login::placeholder, .register::placeholder, .register-phone input::placeholder {
        color: #626363 !important;
    }
    .input-group-text {
        background-color: #009FD4;
        border: 1px solid #009FD4;
        color: white;
    }
 .icon {
    font-size: 1.5em !important;
}
.remember-me {
    margin-left: 10px;
    color: black;
    font-size: 1.2em;
}
.forgot-password {
    color: black;
    font-weight: bold !important;
    font-size: 1.2em;

}
input:focus {
  background-color: white !important;
  border-color: white !important;
  box-shadow: 0 3px 10px 0 rgba(0,0,0, 0.1) !important;
}
</style>