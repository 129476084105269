var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showLoader}},[_c('validation-observer',{ref:"demandValidation"},[_c('b-form',{staticClass:"auth-login-form mt-3"},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"ime","rules":"required|min:3|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"demand",attrs:{"placeholder":"Ime"},model:{value:(_vm.demand.name),callback:function ($$v) {_vm.$set(_vm.demand, "name", $$v)},expression:"demand.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"priimek","rules":"required|min:3|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"demand",attrs:{"placeholder":"Priimek"},model:{value:(_vm.demand.surname),callback:function ($$v) {_vm.$set(_vm.demand, "surname", $$v)},expression:"demand.surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"e-naslov","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"demand",attrs:{"placeholder":"E-naslov"},model:{value:(_vm.demand.email),callback:function ($$v) {_vm.$set(_vm.demand, "email", $$v)},expression:"demand.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('span',{staticStyle:{"position":"absolute","right":"10px","margin-top":"12px"}},[_c('fa',{staticClass:"ml-1",attrs:{"id":"supported-phone-formats-tooltip","icon":"question-circle","color":"#72a5d8"}}),_c('b-tooltip',{attrs:{"target":"supported-phone-formats-tooltip","placement":"topright","triggers":"hover focus","custom-class":"expanded-tooltip"}},[_vm._v(" Podprti formati:"),_c('br'),_vm._v(" +386 51 123 456"),_c('br'),_vm._v(" 00386 51 123 456"),_c('br'),_vm._v(" 051 123 456 ")])],1),_c('validation-provider',{attrs:{"name":"telefonska številka","rules":"required|phone_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"demand",attrs:{"placeholder":"Telefonska številka"},model:{value:(_vm.demand.telephone_number),callback:function ($$v) {_vm.$set(_vm.demand, "telephone_number", $$v)},expression:"demand.telephone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"naslov","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"demand",attrs:{"placeholder":"Naslov (ulica, kraj, poštna št.)"},model:{value:(_vm.demand.address),callback:function ($$v) {_vm.$set(_vm.demand, "address", $$v)},expression:"demand.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"regija","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"demand",attrs:{"placeholder":"Regija"},model:{value:(_vm.demand.region),callback:function ($$v) {_vm.$set(_vm.demand, "region", $$v)},expression:"demand.region"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"sporočilo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{staticClass:"demand",attrs:{"rows":"8","placeholder":"Sporočilo"},model:{value:(_vm.demand.content),callback:function ($$v) {_vm.$set(_vm.demand, "content", $$v)},expression:"demand.content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"text-right mt-2"},[_c('b-button',{staticClass:"login-button",attrs:{"pill":""},on:{"click":_vm.validationFormDemand}},[_vm._v("POŠLJI POPRAVŠEVANJE")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }