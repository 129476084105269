<template>
    <div class="bcard-background bcard-background-mobile">
        <div class="outer-body">
            <h1 class="pt-2 font-large-2 text-center" style="font-weight: bold; color: #72A5D8">Dobrodošli v eSoseski</h1>
            <b-row class="px-0 py-2 login-page" align-content="center">
                <!-- Left Text-->
                <b-col xs="0" lg="6" class="p-0 px-1 d-none d-lg-block">
                    <div v-if="tab===0">
                        <div class="mt-1 d-flex w-100 justify-content-center"> 
                            <b-img style="padding-left: 6rem; padding-right: 6rem;" fluid :src="blazLogin" />
                        </div>
                    </div>
                    <div v-if="tab===1" class="flex-column">
                        <b-row>
                            <b-col cols="1" sm="2" />
                            <b-col cols="11" sm="10">
                                <div class="font-medium-5 text-left w-100">Registrirani uporabniki pridobijo dostop do:</div>
                            </b-col>
                        </b-row>
                        <div class="d-flex w-100 justify-content-center"> 
                            <b-img style="padding-left: 6rem; padding-right: 6rem;" fluid :src="blazRegister" />
                        </div>
                        <b-row>
                            <b-col cols="1"/>
                            <b-col cols="11">
                                <div class="w-100">
                                    <b-row class="" align-content="center">
                                        <b-col cols="2" class="d-flex justify-content-end">
                                            <b-img fluid class="" :src="iconCheckMark" />
                                        </b-col>
                                        <b-col cols="10" class=" font-medium-2 " >
                                            Popustov na izdelke v spletni trgovini eSoseska
                                        </b-col>
                                    </b-row>
                                    <b-row class="my-1" align-content="center">
                                        <b-col cols="2" class="d-flex justify-content-end">
                                            <b-img fluid class="" :src="iconCheckMark" />
                                        </b-col>
                                        <b-col cols="10" class=" font-medium-2 " >
                                            Pestre ponudbe trga nepremičnin
                                        </b-col>
                                    </b-row>
                                    <b-row class="" align-content="center">
                                        <b-col cols="2" class="d-flex justify-content-end">
                                            <b-img fluid class="" :src="iconCheckMark" />
                                        </b-col>
                                        <b-col cols="10" class=" font-medium-2 " >
                                            Aktualnih novic iz stanovanjske tematike
                                        </b-col>
                                    </b-row>
                                    <b-row class="my-1" align-content="center">
                                        <b-col cols="2" class="d-flex justify-content-end">
                                            <b-img fluid class="" :src="iconCheckMark" />
                                        </b-col>
                                        <b-col cols="10" class=" font-medium-2 " >
                                            Ugodnih storitev za vaš dom
                                        </b-col>
                                    </b-row>
                                    <b-row class="" align-content="center">
                                        <b-col cols="2" class="d-flex justify-content-end">
                                            <b-img fluid class="" :src="iconCheckMark" />
                                        </b-col>
                                        <b-col cols="10" class=" font-medium-2 " >
                                            Spletne tržnice z vašimi oglasi
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                    <div v-if="tab===2">
                        <div class="d-flex justify-content-center" >
                            <div class="font-large-1 mt-1 text-left">Ste nezadovoljni z<br> <span style="font-weight: bold;">upravljanjem</span> v vaši<br> večstanovanjski stavbi?</div>
                        </div>
                        <div class="mt-4 d-flex w-100 justify-content-center"> 
                            <b-img style="padding-left: 6rem; padding-right: 6rem;" fluid :src="blazFeedback" />
                        </div>
                    </div>
                </b-col>

                <!-- /Left Text-->

                <!-- Login-->
                <b-col xs="12" lg="6" class="p-0 px-1 mt-2 mt-lg-0">
                    <div class="w-100 mb-2">
                        <b-row class="" align-content="center">
                            <b-col cols="6" sm="4" class="mt-1" style="padding-left: 0.5rem; padding-right: 0.5rem;">
                                <b-button :variant="isFirstTabVariant" class="font-medium-2 w-100 text" @click="tab=0">Prijava</b-button>
                            </b-col>
                            <b-col cols="6" sm="4" class="mt-1" style="padding-left: 0.5rem; padding-right: 0.5rem;">
                                <b-button :variant="isSecondTabVariant" class="font-medium-2 w-100 text " @click="tab=1">Registracija</b-button>
                            </b-col>
                            <b-col cols="6" sm="4" class="mt-1" style="padding-left: 0.5rem; padding-right: 0.5rem;">
                                <b-button :variant="isThirdTabVariant" class="font-medium-2 w-100 text" @click="tab=2">Povpraševanje</b-button>
                            </b-col>
                        </b-row>
                    </div>
                    <b-card class="custom-bcard-right">
                        <b-card-body class="d-flex flex-column">

                            <h1 class="text font-title-main" style="font-weight: bold;">
                                {{getTitle}}
                            </h1>
                
                            <div>                
                                <b-row>
                                    <b-col>
                                        <login-component v-if="tab === 0" @on-login-successful="onLoginSuccessful"/>
                                        <register-component v-else-if="tab === 1" @on-register-successful="onRegisterSuccessfull"/>
                                        <demand-component v-else-if="tab === 2" @on-demand-successful="onDemandSuccessfull"/>
                                    </b-col>
                                </b-row>
                            </div>

                        </b-card-body>

                    </b-card>
                </b-col>
            </b-row>
    
        </div>
    </div>
</template>

<script>
    import {BRow, BCol, BImg, BCard, BCardBody, BButton} from 'bootstrap-vue'
    import LoginComponent from './components/LoginComponent.vue'
    import RegisterComponent from './components/RegisterComponent.vue'
    import DemandComponent from './components/DemandComponent'
    export default {
        components: {
            BRow,
            BCol,
            BImg,
            BCardBody,
            LoginComponent,
            RegisterComponent,
            DemandComponent,
            BCard,
            BButton
        },
        props: {
            type: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                tab: 0,
                blazLogin: require('@/assets/images/login/blaz-login.png'),
                blazRegister: require('@/assets/images/login/blaz-register.png'),
                blazFeedback: require('@/assets/images/login/blaz-feedback.png'),
                iconCheckMark:require('@/assets/images/icons/icon_check circled_outline_.svg'),
                enterKeyupListener: null
            }
        },
        methods: {
            async onLoginSuccessful() {
                try {
                    const res = await this.$http.get('/api/user/v1/check_email_confirmation')
                    if  (res.data.email_confirmed) {
                        window.location = '/e-upravnik'
                    } else {
                        window.location = '/'
                    }
                } catch (err) {
                    this.$printError('Prislo je do napake')

                }
            },
            onRegisterSuccessfull() {
                this.tab = 0
            },
            onDemandSuccessfull() {
                this.tab = 0
            }
        },
        computed: {
            getTitle() {
                if (this.tab === 0) return 'Prijava'
                else if (this.tab === 1) return 'Registracija'
                return 'Povpraševanje'
            },
            isFirstTabVariant() {
                if (this.tab === 0) {
                    return 'primary'
                } else {
                    return 'secondary'
                }
            },
            isSecondTabVariant() {
                if (this.tab === 1) {
                    return 'primary'
                } else {
                    return 'secondary'
                }
            },
            isThirdTabVariant() {
                if (this.tab === 2) {
                    return 'primary'
                } else {
                    return 'secondary'
                }
            }
        },
        watch: {
            type(val) {
                this.tab = val
            }
        },
        mounted() {
            this.tab = this.type
        }

    }
</script>

<style scoped>
    .bcard-body-separate{
        border-radius: 10px !important;
    }
    .bcard-background{
        box-shadow: none !important;
        margin-left: -2000px !important;
        margin-right: -2000px !important;
        background: linear-gradient(180deg, rgba(234,243,249,1) 0%, rgba(234,243,249,1) 85%, rgba(255,255,255,1) 95%);
        min-height: 100vh !important;
    }

    @media only screen and (max-width: 1199px) {
        .bcard-background-mobile {
            margin-top: -150px!important;
            padding-top: 150px!important;
        }
    }
    .font-title-main{
        color: #72A5D8 !important;
        font-weight: 900 !important;
        font-size: 1.9rem;
    }
    .text-under-image{
        padding-top: 10px;
        font-size: 1.15em !important;
        color: #72A5D8;
        font-weight: bold;
        word-break: break-word;
    }
     .outer-body{
        margin-right: 2000px !important;
        margin-left: 2000px !important;
        border-radius: 10px;
        padding: 0px !important;
        background: transparent !important;
    }

    .outer-body > div.card-body{
        padding: 0px !important;
    }

    .custom-bcard-left .card-body{
        padding: 0px !important;
        border-radius: 10px !important;
    }

    .custom-bcard-right .card-body{
        border-radius: 10px !important;
    }

    .top-banner{
        background: #72A5D8;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }
    .welcome-card img {
        max-width: 115px !important;
    }

    .last-image img {
        max-width: 108px !important;
    }
  

    /* .custom-bcard-right {
        min-height: 50vh !important;
    } */
</style>
<style>
    .login-page .card{
        border-radius: 10px !important;
    }
    .login-page .check {
        display: inline-block;
        position: relative;
        cursor: pointer;

    }
    .login-page .check input {
        opacity: 0;
    }
    .login-page .mark {
        position: absolute;
        top: 0;
        left: 0;
        height: 17px;
        width: 17px;
        background-color: #fff;
        padding: 0px !important;
    }
    
    .login-page .mark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .login-page .check input:checked ~ .mark:after {
        display: block;
    }

    .login-page .check .mark{
        width: 21px;
        height: 21px;
        border: 1px solid black;
        border-radius:50%;
        outline:none;
    }
    .login-page .check .mark:after {
        width: 100%;
        border: 4px solid white;
        height: 100%;
        background-color: #72A5D8;
        border-radius: 50% !important;
    }
    .login-page .form-control,
.login-page .input-group-text {
    border-radius: 0px !important;
}
.login-page .form-control {
    background-color: #F4F4F4 !important;
    padding-top: 1.75em !important;
    padding-bottom: 1.75em !important;
    border: none;
}
.login-page .form-control:focus {
    box-shadow: none;
    transition: border-color 0.15s ease-in-out !important;
}
.login-page .form-control::placeholder {
    color: black !important;
}
.login-page .card {
    box-shadow: none !important
}
.btn-secondary, .btn-secondary:hover, .btn-secondary:active, .btn-secondary:visited {
    background-color: #FFFFFF !important;
    border-color: #FFFFFF !important;
    color: black !important;
}
</style>